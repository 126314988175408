// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
.backdropSpinner.MuiBackdrop-root {
  width: 100%;
  height: 100%;
  background-color: rgb(30, 30, 30, 30%);
  z-index: 2;
}

.backdropSpinner .MuiCircularProgress-root {
  color: var(--primaryColor);
}
`, "",{"version":3,"sources":["webpack://./src/views/components/base/backdropSpinner/BackdropSpinner.css"],"names":[],"mappings":"AAAA;;;CAGC;AACD;EACE,WAAW;EACX,YAAY;EACZ,sCAAsC;EACtC,UAAU;AACZ;;AAEA;EACE,0BAA0B;AAC5B","sourcesContent":["/*\nThis computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), \nis the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.\n*/\n.backdropSpinner.MuiBackdrop-root {\n  width: 100%;\n  height: 100%;\n  background-color: rgb(30, 30, 30, 30%);\n  z-index: 2;\n}\n\n.backdropSpinner .MuiCircularProgress-root {\n  color: var(--primaryColor);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
