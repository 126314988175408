/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/* This file consists of app insight event data and posting function call */

import { appInsights } from './ApplicationInsights'
import utils from '../../../utils/utils'
const appInsightData = {
  name: {
    privacyPolicy: 'Privacy policy',
    cookiePolicy: 'Cookie policy',
    accessibility: 'Accessibility',
    breadcrumbHome: 'Home -Breadcrumb',
    backButton: 'Back button',
    acceptCookies: 'Accept Cookies',
    rejectCookies: 'No thanks or Reject Cookies',
    makePaymentFromHome: 'Make Payment - Home',
    addCases: 'Add cases',
    addMoreCases: 'Add More Case',
    caseRemovedFromAddPopup: 'Case Removed',
    addCaseToList: 'Add Case to List',
    caseRemovedFromList: 'Case Removed from List',
    payment: 'Payment',
    backToCaseDetails: 'Back to Case Details',
    retryPayment: 'Retry Payment',
    closePaymentOnSuccess: 'Close - Payment Success',
    playStore: 'Download app - Playstore',
    appStore: 'Download app - AppStore',
    languageChange: 'Language change',
  },
  customProperty: {
    privacyPolicy: 'Selected privacy policy hyperlink',
    cookiePolicy: 'Selected cookie policy hyperlink',
    accessibility: 'Selected accessibility hyperlink',
    breadcrumbHome: 'Navigated to home through breadcrumbs',
    backButton: 'Invoked back button',
    acceptCookies: 'Accepted cookies',
    rejectCookies: 'Rejected cookies',
    makePaymentFromHome: `Selected 'make payment' in home screen`,
    addCases: `Invoked 'Add cases'`,
    addMoreCases: `Invoked 'Add More' in add case pop-up`,
    caseRemovedFromAddPopup: `Invoked 'Remove' in add case pop-up`,
    addCaseToList: `Invoked 'Add' to add case(s) to list`,
    caseRemovedFromList: `Removed case(s) from the List`,
    payment: 'Proceeded to make full payment',
    backToCaseDetails: 'Back to Case Details',
    retryPayment: 'Retry Payment',
    closePaymentOnSuccess: 'Close - Payment Success',
    playStore: `Selected 'Download App' - Play Store`,
    appStore: `Selected 'Download App' - App Store`,
    languageChange: `Changed language to __languageName__`,
  },
}

const appInsightsEventTrack = (appInsightDataKey, languageName) => {
  const cookieData = utils?.getCookies()
  if (
    Object.keys(cookieData)?.length === 0 ||
    (Object.keys(cookieData)?.length > 0 && cookieData?.accept === true)
  ) {
    const eventTrack = {
      name: appInsightData?.name?.hasOwnProperty(appInsightDataKey)
        ? appInsightData?.name[appInsightDataKey]
        : '',
      properties: {
        TriggeredEvent: appInsightData?.customProperty?.hasOwnProperty(
          appInsightDataKey
        )
          ? appInsightData?.customProperty[appInsightDataKey]
              ?.toString()
              ?.replace('__languageName__', languageName)
          : '',
      },
    }
    appInsights?.trackEvent(eventTrack)
  }
}

export { appInsightsEventTrack }
