/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  It contains recoil's atoms files to manage the state value(s)
  atom  : This is an recoil's default library to manage the global state values, This atom is an function and it recieve parameter as object with containing key default values.
  key   : This is an unique key to access this atom function from component.
*/
/* istanbul ignore file */
// System defined variables
import { atom } from 'recoil'
import { recoilPersist } from 'recoil-persist'

const { persistAtom } = recoilPersist({
  key: 'recoil-persist',
  storage: sessionStorage,
})

const appVariableConfiguration = atom({
  key: 'appVariableConfiguration',
  default: null,
})

const customerTokenDetailsAtom = atom({
  key: 'customerTokenDetailsAtom',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const customerDetailsAtom = atom({
  key: 'customerDetailsAtom',
  default: null,
  effects_UNSTABLE: [persistAtom],
})
const caseListDetailsAtom = atom({
  key: 'caseListDetailsAtom',
  default: [],
  effects_UNSTABLE: [persistAtom],
})
const customerCaseListDetailsAtom = atom({
  key: 'customerCaseListDetailsAtom',
  default: [],
})
const refreshCustomerCaseListAtom = atom({
  key: 'refreshCustomerCaseListAtom',
  default: false,
})

const screenResolution = atom({
  key: 'screenResolution',
  default: {},
})
const languageSelect = atom({
  key: 'languageSelect',
  default: null,
})
const authenticationFlowAtom = atom({
  key: 'authenticationFlowAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

const toastMessage = atom({
  key: 'toastMessage',
  default: { message: '', status: '' },
})

const isLoggedInAtom = atom({
  key: 'isLoggedInAtom',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

const navigateToLoginAtom = atom({
  key: 'navigateToLoginAtom',
  default: false,
})
const payCaseNumberData = atom({
  key: 'payCaseNumberData',
  default: false,
  effects_UNSTABLE: [persistAtom],
})
const keepReferenceForActionClickAtom = atom({
  key: 'keepReferenceForActionClickAtom',
  default: null,
})
const customLocationHistoryAtom = atom({
  key: 'customLocationHistoryAtom',
  default: '',
})

export {
  appVariableConfiguration,
  customerTokenDetailsAtom,
  customerDetailsAtom,
  caseListDetailsAtom,
  customerCaseListDetailsAtom,
  screenResolution,
  languageSelect,
  toastMessage,
  isLoggedInAtom,
  refreshCustomerCaseListAtom,
  navigateToLoginAtom,
  payCaseNumberData,
  keepReferenceForActionClickAtom,
  authenticationFlowAtom,
  customLocationHistoryAtom,
}
