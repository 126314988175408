/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this file, we configured URL and componenet for that URL
  #lazy           : Lazy loading is a technique that enables us to load a specific component when a particular route is accessed. 
                    It exponentially enhances the load time and the loading speed. At the same time, it increases the react application performance.
  #React.Suspense : React Suspense here to offer a fallback React element when the component is lazy loaded from the server.
*/

import React, { lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
/* const VerificationCode = lazy(() =>
  import('../pages/verificationCode/VerificationCode')
) */
const CookiePolicy = lazy(() =>
  import('../components/custom/cookiePolicy/CookiePolicy')
)

const PaymentSuccess = lazy(() => import('../pages/payment/PaymentSuccess'))
const PaymentFailure = lazy(() => import('../pages/payment/PaymentFailure'))
const WelcomePage = lazy(() => import('../pages/welcomePage/WelcomePage'))
const CaseList = lazy(() => import('../pages/caseList/CaseList'))
const RouteMiddleWare = lazy(() => import('./RoutesMiddleWare'))
/* const NewUser = lazy(() => import('../pages/newUser/NewUser'))
const Login = lazy(() => import('../pages/login/Login'))
const CustomerCaseList = lazy(() =>
  import('../pages/customerCaseList/CustomerCaseList')
) */

const renderProtectedComponent = (
  component,
  validateRoute,
  redirectComponent
) => {
  return (
    <RouteMiddleWare
      validateRoute={validateRoute}
      redirectComponent={redirectComponent}
    >
      {component}
    </RouteMiddleWare>
  )
}

const RoutesFile = () => {
  return (
    <React.Suspense fallback={<div className="suspense-load-page"></div>}>
      <Routes>
        <Route
          exact
          path="/"
          element={renderProtectedComponent(<WelcomePage />, true, null)}
        />
        <Route
          exact
          path="/caselist"
          element={renderProtectedComponent(<CaseList />, true, null)}
        />
        {/* <Route
          exact
          path="/customercaselist"
          element={renderProtectedComponent(<CustomerCaseList />, true, null)}
        /> */}
        <Route
          exact
          path="/payment-success"
          element={renderProtectedComponent(<PaymentSuccess />, true, null)}
        />
        <Route
          exact
          path="/payment-failure"
          element={renderProtectedComponent(<PaymentFailure />, true, null)}
        />
        {/* <Route
          exact
          path="/new-user"
          element={renderProtectedComponent(<NewUser />, true, null)}
        />
        <Route
          exact
          path="/verificationCode"
          element={renderProtectedComponent(<VerificationCode />, true, null)}
        />
        <Route
          exact
          path="/login"
          element={renderProtectedComponent(<Login />, true, null)}
        /> */}
        <Route
          exact
          path="/cookiepolicy"
          element={renderProtectedComponent(<CookiePolicy />, true, null)}
        />
        <Route exact path="*" element={<p>Page Not Found</p>} />
      </Routes>
    </React.Suspense>
  )
}

export default RoutesFile
