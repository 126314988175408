/* istanbul ignore file */
// System defined variables
import { Component } from 'react'
// Custom defined variables
import { load } from './config'

export default class ConfigLoader extends Component {
  constructor(props) {
    super(props)
    this.state = { isLoaded: false }
  }

  componentDidMount() {
    // Once the configuration is loaded set `isLoaded` to true so we know to render our component
    load().then((config) => this.setState({ isLoaded: true, config }))
  }

  render() {
    const { isLoaded, config } = this.state
    const { loading, ready } = this.props
    // If we haven't yet loaded the config, show either a "splash" component provided via a `loading` props or return nothing.
    if (!isLoaded) {
      return loading ? loading() : null
    }

    // The config is loaded so show the component set on the `ready()` props
    return ready(config)
  }
}
