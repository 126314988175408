/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this component we write code to configure the props with button component
  onClickHandler  : onClickHandler is received from parent component to pass the button click functionality using this callback function method
  className       : className is received from parent component to apply the styles of the button component
  field           : field is received from parent component to configure the value of button component
*/

import React from 'react'
import { Button } from '@mui/material'
import PropTypes from 'prop-types'

import './Button.css'

import utils from '../../../../utils/utils'
import { appInsightsEventTrack } from '../../../pages/applicationInsights/ApplicationInsightDataMap'

const ButtonComponent = ({ field, className, onClickHandler, ...rest }) => {
  function onClick(event) {
    if (field?.appInsightDataKey) {
      appInsightsEventTrack(field?.appInsightDataKey)
    }
    if (onClickHandler) onClickHandler(event)
  }
  return (
    <Button
      id={field?.value}
      disableRipple
      disableElevation
      className={`button btn--${field?.type || 'primary'} ${className || ''}`}
      variant={field && field?.variant}
      disabled={field?.disabled}
      onClick={(event) => {
        onClick(event)
      }}
      style={field?.buttonStyle ?? {}}
      data-testid={`${rest?.dataTestId ? rest?.dataTestId : 'button'}`}
      startIcon={field?.startIcon}
      endIcon={field?.endIcon}
      color={field?.color}
    >
      {field?.label}
    </Button>
  )
}
ButtonComponent.propTypes = {
  field: PropTypes.objectOf(PropTypes.any),
  className: PropTypes.string,
  onClickHandler: PropTypes.func,
}

ButtonComponent.defaultProps = {
  field: {},
  className: '',
  onClickHandler: () => null,
}

export default ButtonComponent
