/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
  In this files we configured router and menubar details
  #MenuLayout     : In this menulayout we mapped all our 
  #BrowserRouter  : BrowserRouter is a router implementation that uses the HTML5 history API (pushstate, replacestate, and popstate events) to keep your UI in sync with the URL.
                    It is the parent component used to store all other components.
  #RecoilRoot     : Recoil used to manage the global state value(s)
*/

// System defined variables
import React, { useState, useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'
import { useIdleTimer } from 'react-idle-timer'
// Custom defined variables
import RoutesFile from '../views/routes/Routes'
import ErrorBoundary from '../views/components/custom/errorBoundary/ErrorBoundary'
import Header from '../views/components/custom/header/Header'
import Footer from '../views/components/custom/footer/Footer'
import DownloadApp from '../views/components/custom/downloadApp/DownloadApp'
import {
  appVariableConfiguration,
  screenResolution,
  navigateToLoginAtom,
  isLoggedInAtom,
} from '../views/components/recoil/atoms/atoms'
import Toast from '../views/components/base/toast/Toast'
import BackdropSpinner from '../views/components/base/backdropSpinner/BackdropSpinner'
import CookieBanner from '../views/components/custom/cookieBanner/CookieBanner'
import { setAppInsightsKey } from '../views/pages/applicationInsights/ApplicationInsights'
import utils from '../utils/utils'

import './App.css'
import './RootStyle.css'

function App({ appConfig }) {
  const setAppVariableConfiguration = useSetRecoilState(
    appVariableConfiguration
  )
  const setScreenResolution = useSetRecoilState(screenResolution)

  const isLoggedIn = useRecoilValue(isLoggedInAtom)
  const setNavigateToLogin = useSetRecoilState(navigateToLoginAtom)
  const onIdle = () => {
    if (isLoggedIn) {
      setNavigateToLogin(true)
      // console.log('Navigate to Login')
    }
    // console.log('user is idle')
  }

  /* istanbul ignore next */
  // ignoring this due to the second phase of payment(register-flow) is yet to be implemented
  const { idleDetector } = useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle,
  })
  const [cookieStorage, setCookieStorage] = useState(utils?.getCookies)
  const [showCookieBanner, setShowCookieBanner] = useState(false)

  useEffect(() => {
    setAppVariableConfiguration(appConfig)
    const appInsightsKey = appConfig?.AppInsightsKey
    if (appInsightsKey) {
      setAppInsightsKey(appInsightsKey)
    }

    // cookies
    const cookieData = utils?.getCookies()
    setCookieStorage(cookieData)
    if (Object.keys(cookieData)?.length > 0) {
      setShowCookieBanner(false)
    } else {
      setShowCookieBanner(true)
    }
  }, [])

  const setBodyStylesProperty = () => {
    document.body.offsetWidth
    if (document.body.offsetWidth >= 300 && document.body.offsetWidth < 799) {
      setScreenResolution({
        screenResolution: 'mobile',
        offsetWidth: document.body.offsetWidth,
      })
    } else if (
      document.body.offsetWidth >= 799 &&
      document.body.offsetWidth < 1134
    ) {
      setScreenResolution({
        screenResolution: 'tablet',
        offsetWidth: document.body.offsetWidth,
      })
    } else {
      setScreenResolution({
        screenResolution: 'desktop',
        offsetWidth: document.body.offsetWidth,
      })
    }
  }

  useEffect(() => {
    setBodyStylesProperty()
    window.addEventListener('resize', setBodyStylesProperty)
    // Clean up the event listener on unmount

    return () => {
      window.removeEventListener('resize', setBodyStylesProperty)
      // window.removeEventListener('load', hideSafariURLBar)
    }
  }, [])
  setInterval(() => {
    const bodyStyle = window.getComputedStyle(document.body)
    const bodyTop = parseInt(bodyStyle.top, 0) || 0
    if (bodyStyle !== 0) {
      const targetElement = document.querySelector('.responsivelayout')
      if (targetElement) {
        targetElement.style.height = `calc(100vh - ${bodyTop}px)`
      }
      const targetElementpopup = document.querySelector('.menuPopupBackground')
      if (targetElementpopup) {
        targetElementpopup.style.paddingTop = `${bodyTop}px`
      }
    }
  }, 100) // Check every 100ms until the element is available

  return (
    <BrowserRouter>
      <BackdropSpinner />
      <Toast />
      {/* <GoogleTranslate /> */}
      <div className="responsivelayout">
        <Header />
        <div className="contentArea">
          <RoutesFile />

          <div className="footerArea">
            <DownloadApp />
            <Footer />
          </div>
        </div>
      </div>
      {showCookieBanner ? (
        <CookieBanner
          setShowCookieBanner={setShowCookieBanner}
          setCookieStorage={setCookieStorage}
        />
      ) : null}
    </BrowserRouter>
  )
}

export default App
