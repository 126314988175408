/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
  This Toast component is used to show any messages with color indication based on status - error, success, warning
  #toastMessage - The atom which will have the text and status of the message, which will be updated by components or pages
  */
// System defined variables
import React, { useEffect, useState } from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import parse from 'html-react-parser'
import { useRecoilValue } from 'recoil'

// Custom defined variables
import { toastMessage } from '../../recoil/atoms/atoms'
import successToastIcon from '../../../../assets/static/toast/successToastIcon.svg'
import errorToastIcon from '../../../../assets/static/toast/errorToastIcon.svg'
import './Toast.css'
import { ReactSVG } from 'react-svg'

const Toast = () => {
  const toastContent = useRecoilValue(toastMessage)
  const [open, setOpen] = useState(false)
  const [message, setMessage] = useState(false)
  const [statusMessage, setStatusMessage] = useState('success')

  const handleClose = () => {
    setOpen(false)
  }

  /* istanbul ignore next */
  // ignoring this due to the jest error,
  /* Element type is invalid: expected a string (for built-in components) or a class/function (for composite components) but got: undefined. You likely forgot to export your component from the file it's defined in, or you might have mixed up default and named imports.*/
  //usage of React SVG to render the svg icons
  React.useEffect(() => {
    if (toastContent?.message) {
      //generate message based on message content in toastMessage atom
      setOpen(true)
      let toastMessageText = ''
      let errStatus = toastContent?.status
      //generate message with break tag based on type of toastMessage atom
      if (
        toastContent?.message &&
        typeof toastContent?.message === 'object' &&
        Array.isArray(toastContent.message)
      ) {
        const messageGenerated = generateMessage(
          toastContent?.message,
          toastContent?.status
        )
        toastMessageText = messageGenerated.toastMessageText
        errStatus = messageGenerated.errStatus
      } else if (
        toastContent?.message &&
        typeof toastContent?.message === 'object'
      ) {
        const errorMessageObject = Object.entries(toastContent?.message)
        errorMessageObject.forEach((err) => {
          toastMessageText += `<span>${err[1]}</span><br />`
        })
      } else {
        toastMessageText = toastContent?.message
      }
      setStatusMessage(errStatus)
      setMessage(toastMessageText)
    }
  }, [toastContent])

  //generate message content with br tag to render array type of messages
  const generateMessage = (messageList, status) => {
    let toastMessageText = ''
    let errStatus = status

    messageList?.forEach((item, index) => {
      toastMessageText += `<span>${item}</span>`
      if (index !== messageList?.length - 1) toastMessageText += `<br/>`
    })
    return { toastMessageText, errStatus }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (open && toastContent?.status === 'success') {
        setOpen(false)
      }
    }

    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [open])

  return (
    <>
      {message ? (
        <Snackbar
          id="Mui_Alert"
          data-testid="test_toast"
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
          className="toast-root"
          open={open}
        >
          <Alert
            elevation={6}
            variant="outlined"
            severity={statusMessage}
            iconMapping={{
              error: (
                <ReactSVG className="toastStatusIcon" src={errorToastIcon} />
              ),
              success: (
                <ReactSVG className="toastStatusIcon" src={successToastIcon} />
              ),
            }}
            onClose={handleClose}
          >
            {message && parse(message)}
          </Alert>
        </Snackbar>
      ) : (
        <></>
      )}
    </>
  )
}

export default Toast
