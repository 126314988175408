/*
 This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC),
 is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
 */

/*
  This header component developed for display the contract logo and name
*/
// System defined variables
import React, { useState, useEffect } from 'react'
import { useRecoilValue, useRecoilState, useSetRecoilState } from 'recoil'
import { Menu as MenuIcon, Close as CloseIcon, Check } from '@material-ui/icons'

import {
  List,
  ListItemIcon,
  ListItemText,
  ListItemButton,
  Popover,
} from '@mui/material'

// Custom defined variables
import './Header.css'
import headerLogo from './MH-logo.svg'
import language from './language.svg'
import userAccountIcon from './userAccountIcon.svg'
import textJson from '../../../../json/Text.json'
import Dropdown from '../../base/dropdown/Dropdown'
import {
  screenResolution,
  languageSelect,
  isLoggedInAtom,
  customerDetailsAtom,
  navigateToLoginAtom,
  authenticationFlowAtom,
} from '../../../../views/components/recoil/atoms/atoms'
import Button from '../../base/button/Button'
import { appInsightsEventTrack } from '../../../pages/applicationInsights/ApplicationInsightDataMap'

const Header = () => {
  const { staticHeaderData } = textJson
  const [selectedLanguage, setSelectedLanguage] = useRecoilState(languageSelect)
  const [selectLanguage, setSelectLanguage] = useState(selectedLanguage || 'en')
  const [showUserAccountPopoverOnDesktop, setShowUserAccountPopoverOnDesktop] =
    useState(false)
  const [userAccountAnchorElement, setUserAccountAnchorElement] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useRecoilState(isLoggedInAtom)
  const customerDetails = useRecoilValue(customerDetailsAtom)
  const setNavigateToLogin = useSetRecoilState(navigateToLoginAtom)
  const authenticationFlow = useRecoilValue(authenticationFlowAtom)

  const onchangeLanguage = (event, params) => {
    setSelectLanguage(params?.value)
    const language = params?.value
    const googleTranslateContainer = document.querySelector('.goog-te-combo')
    if (googleTranslateContainer) {
      googleTranslateContainer.value = language
      googleTranslateContainer.dispatchEvent(new Event('change'))
    }
    if (googleTranslateContainer?.value === '') {
      googleTranslateContainer.value = language
      googleTranslateContainer?.dispatchEvent(new Event('change'))
    }
    addToAppInsights(event?.currentTarget?.innerText)
    changeLanguage(language)
  }
  const changeLanguage = (languageCode) => {
    const combo = document.querySelector('.goog-te-combo')
    if (combo) {
      combo.value = languageCode
      combo.dispatchEvent(new Event('change'))
    }
  }
  const screenResolutionDevices = useRecoilValue(screenResolution)

  const resolutionMobileDevices =
    screenResolutionDevices?.screenResolution === 'mobile' ? true : false
  const [openMenuModalWindow, setMenuModalWindow] = useState(false)

  useEffect(() => {
    if (
      isLoggedIn === false ||
      screenResolutionDevices?.screenResolution === 'mobile'
    ) {
      handleCloseOfUserAccountPopover()
    }
  }, [isLoggedIn, screenResolutionDevices])

  // useEffect(() => {}, [screenResolutionDevices])
  const menuIconOnclick = (isOpen) => {
    setMenuModalWindow(isOpen)
  }
  const addToAppInsights = (language) => {
    appInsightsEventTrack('languageChange', language)
  }

  const handleListItemClick = (languageSelect, languageName) => {
    setSelectLanguage(languageSelect)
    setSelectedLanguage(languageSelect)
    const language = languageSelect
    const googleTranslateContainer = document.querySelector('.goog-te-combo')
    if (googleTranslateContainer) {
      googleTranslateContainer.value = language
      googleTranslateContainer.dispatchEvent(new Event('change'))
    }
    if (googleTranslateContainer?.value === '') {
      googleTranslateContainer.value = language
      googleTranslateContainer?.dispatchEvent(new Event('change'))
    }
    addToAppInsights(languageName)
    changeLanguage(language)
  }
  const onClickUserAccountIcon = (event) => {
    setUserAccountAnchorElement(event?.currentTarget)
    setShowUserAccountPopoverOnDesktop(true)
  }
  const handleCloseOfUserAccountPopover = () => {
    setUserAccountAnchorElement(null)
    setShowUserAccountPopoverOnDesktop(false)
  }
  const logoutUser = () => {
    setNavigateToLogin(true)
    if (menuIconOnclick) {
      menuIconOnclick(false)
    }
    if (showUserAccountPopoverOnDesktop) {
      handleCloseOfUserAccountPopover()
    }
  }
  const onClickHandlerForTermsAndConditions = () => {
    window.open(
      'https://marstonholdings.co.uk/marston-holdings-terms-and-conditions/',
      '_blank'
    )
  }
  const onClickHandlerForPrivacyPolicy = () => {
    window.open(
      'https://marstonholdings.co.uk/marston-holdings-privacy-notice/',
      '_blank'
    )
  }
  const [hiddenDomValue, setHiddenDomValue] = useState('')
  /* istanbul ignore next */
  useEffect(() => {
    const hiddenDomDateValue = new Date()
    setHiddenDomValue(hiddenDomDateValue)
  }, [selectLanguage, selectedLanguage])
  return (
    <div className="header">
      <div className="container">
        <div className="header__primary">
          <div className="header__logo">
            <div className="header-logo-text">
              {headerLogo?.toString()?.trim()?.length > 0 && (
                <img
                  alt="Wokingham Council logo"
                  className="img-logo"
                  src={headerLogo}
                />
              )}
              {authenticationFlow === false ? (
                <>
                  <div class="vertical-line"></div>
                  <div className="paymentText">
                    {staticHeaderData?.paymentText}
                  </div>
                </>
              ) : null}
            </div>
          </div>
          {!resolutionMobileDevices && (
            <div className="contact-detail-section">
              <div className="contact-detail">
                <div className="header-contact-details-text hide">
                  <div> {staticHeaderData?.callNumber}</div>
                  <div className="header-working-detail">
                    {staticHeaderData?.callWorkingDetail}
                  </div>
                </div>
              </div>
              <div className="header-language-container">
                <div className="header-language-div">
                  <div class="vertical-line-manage hide"></div>

                  <div className="language-container">
                    {language?.toString()?.trim()?.length > 0 && (
                      <img
                        alt="Wokingham Council logo"
                        className="img-logo"
                        src={language}
                      />
                    )}
                    <div
                      id="google_translate_element1"
                      className="language-selection notranslate"
                    >
                      <Dropdown
                        id="ddl_language"
                        name="ddl_language"
                        dataTestId="ddl_language"
                        Field={{
                          fieldValue: '',
                          defaultValue: '',
                          dataTestId: 'ddl_language',
                          datasource: [
                            {
                              id: 'en',
                              name: 'English',
                            },
                            {
                              id: 'cy',
                              name: 'Welsh',
                            },
                          ],
                          textField: 'name',
                          valueField: 'id',
                          className: 'colWiseControl',
                          labelErrorClass: false,
                          testId: 'ddl_language',
                          translate: true,
                        }}
                        onChangeHandler={onchangeLanguage}
                        value={selectLanguage}
                      />
                    </div>
                  </div>

                  <div className="google-translate-dev notranslate">
                    {'Google Translate'}
                  </div>
                </div>
              </div>
              {isLoggedIn && (
                <div className="userAccountAndSeparatorDiv">
                  <div class="vertical-line"></div>
                  <div
                    className="user-account-icon-div"
                    data-testid="userAccountIconDiv"
                  >
                    {userAccountIcon?.toString()?.trim()?.length > 0 && (
                      <img
                        data-testid="userAccountIcon"
                        alt="user account"
                        className="img-logo user-account-icon"
                        src={userAccountIcon}
                        onClick={onClickUserAccountIcon}
                      />
                    )}
                  </div>
                </div>
              )}
              <Popover
                id={'userAccountPopover'}
                open={showUserAccountPopoverOnDesktop}
                anchorEl={userAccountAnchorElement}
                onClose={handleCloseOfUserAccountPopover}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{ horizontal: 'right' }}
              >
                <div className="user-account-content-div">
                  <div className="loggedInUsernameDiv">
                    <p className="popover-user-content">
                      {customerDetails?.Customername}
                    </p>
                  </div>
                  <div className="list-content-div">
                    <div>
                      <p
                        className="popover-policy-content cursorPointer"
                        onClick={onClickHandlerForTermsAndConditions}
                      >
                        {
                          textJson?.staticHeaderData?.userAccountPopover
                            ?.termsAndConditions
                        }
                      </p>
                    </div>
                    <div>
                      <p
                        className="popover-policy-content cursorPointer"
                        onClick={onClickHandlerForPrivacyPolicy}
                      >
                        {
                          textJson?.staticHeaderData?.userAccountPopover
                            ?.privacyPolicy
                        }
                      </p>
                    </div>
                    <div className="separator"></div>
                    <div>
                      <p
                        className="popover-user-content cursorPointer"
                        data-testid="logout"
                        onClick={logoutUser}
                      >
                        {textJson?.staticHeaderData?.userAccountPopover?.logout}
                      </p>
                    </div>
                  </div>
                </div>
                <input
                  id="hiddendiv"
                  className="hiddendiv"
                  value={hiddenDomValue}
                />
              </Popover>
            </div>
          )}
          {resolutionMobileDevices && (
            <div className="menuIcon">
              <MenuIcon
                data-testid="menuIcon"
                title="closePopupIcon"
                style={{ color: 'white' }}
                fontSize="small"
                onClick={() => {
                  menuIconOnclick(true)
                }}
              />
            </div>
          )}
        </div>
      </div>
      {openMenuModalWindow && (
        <div className="menuPopupBackground">
          <div className="menuHeader">
            <div className="menuTittle">{'Menu'}</div>
            <div className="closeIconContainer">
              <CloseIcon
                data-testid={'closeIcon'}
                onClick={() => {
                  menuIconOnclick(false)
                }}
              >
                X
              </CloseIcon>
            </div>
          </div>
          <div className="menuContent">
            <div className="listOfMenuContent">
              {isLoggedIn && (
                <div className="usernameDiv">
                  <p className="usernameContent">
                    {customerDetails?.Customername}
                  </p>
                </div>
              )}
              <div className="language-section">
                <div className="language-header-title">
                  {staticHeaderData?.language}
                </div>
                <div className="language-selection-label notranslate">
                  <List
                    component="nav"
                    aria-label="main mailbox folders"
                    data-testid="langTranslate"
                  >
                    {staticHeaderData?.languageSelection.map(
                      (menuItem, menuIdx) => {
                        return (
                          <ListItemButton
                            selected={selectLanguage === menuItem?.id}
                            onClick={(event) =>
                              handleListItemClick(menuItem?.id, menuItem?.name)
                            }
                            data-testid={`lang${menuIdx}`}
                            disableRipple
                          >
                            <ListItemText primary={menuItem?.name} />
                            {selectLanguage === menuItem?.id && (
                              <ListItemIcon>
                                <Check fontSize="small" />
                              </ListItemIcon>
                            )}
                          </ListItemButton>
                        )
                      }
                    )}
                  </List>
                </div>
              </div>
              <div className="separator"></div>
              {isLoggedIn && (
                <div className="policyDiv">
                  <p className="" onClick={onClickHandlerForTermsAndConditions}>
                    {
                      textJson?.staticHeaderData?.userAccountPopover
                        ?.termsAndConditions
                    }
                  </p>
                </div>
              )}
              {isLoggedIn && (
                <div className="policyDiv">
                  <p className="" onClick={onClickHandlerForPrivacyPolicy}>
                    {
                      textJson?.staticHeaderData?.userAccountPopover
                        ?.privacyPolicy
                    }
                  </p>
                </div>
              )}
              {isLoggedIn && <div className="separator"></div>}
              {isLoggedIn && (
                <div className="logoutButtonDiv mobileResponsiveButtonDiv">
                  <Button
                    field={{
                      value: 'logout',
                      type: 'primary',
                      label: `${textJson?.staticHeaderData?.userAccountPopover?.logout}`,
                    }}
                    className="mobileResponsive"
                    onClickHandler={logoutUser}
                  />
                </div>
              )}
            </div>
            <div className="menu-contact-details-text hide">
              <div> {staticHeaderData?.callNumber}</div>
              <div className="header-working-detail">
                {staticHeaderData?.callWorkingDetail}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}
export default Header
