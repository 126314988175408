/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
import React from 'react'
import { useNavigate } from 'react-router-dom'
import textJson from '../../../../json/Text.json'
import { appInsightsEventTrack } from '../../../pages/applicationInsights/ApplicationInsightDataMap'

import './Footer.css'

const Footer = () => {
  const navigate = useNavigate()

  const onClickHandlerForAccessibility = () => {
    appInsightsEventTrack('accessibility')
    window.open('https://payments.marstonholdings.co.uk/Accessibility.aspx/')
  }
  const onClickHandlerForCookiePolicy = () => {
    appInsightsEventTrack('cookiePolicy')
    navigate('/cookiePolicy')
  }
  const onClickHandlerForPrivacyPolicy = () => {
    appInsightsEventTrack('privacyPolicy')
    window.open(
      'https://marstonholdings.co.uk/marston-holdings-privacy-notice/',
      '_blank'
    )
  }
  return (
    <div className="footer">
      <div className="footerDiv">
        <div className="about">
          <p className="">{textJson?.footer?.aboutMarston}</p>
          <p className="">
            <span className="registration">
              {textJson?.footer?.registration}
            </span>
            <span className="vat">{textJson?.footer?.vatNumber}</span>
          </p>

          <p className="">{textJson?.footer?.call}</p>

          <p className="">{textJson?.footer?.openingHours}</p>
        </div>
        <div>
          <p className="">
            <span
              data-testid="privacyPolicy"
              className="cursorPointer"
              onClick={() => onClickHandlerForPrivacyPolicy()}
            >
              {textJson?.footer?.privacyPolicy} {` | `}
            </span>
            <span
              data-testid="cookiePolicy"
              className="cursorPointer"
              onClick={() => onClickHandlerForCookiePolicy()}
            >
              {textJson?.footer?.cookiePolicy} {` | `}
            </span>
            <span
              data-testid="accessibility"
              className="cursorPointer"
              onClick={() => onClickHandlerForAccessibility()}
            >
              {textJson?.footer?.accessibility} {` | `}
            </span>
            <span className="">{textJson?.footer?.copyrights}</span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
