/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/* This file express content to download and how to download the app through online */

// System defined variables
import React from 'react'
import { useRecoilValue } from 'recoil'
// Custom defined variables
import textJson from '../../../../json/Text.json'
import downloadAppBackground from '../../../../assets/static/downloadApp/downloadAppBackground.jpg'
import qrAppStore from '../../../../assets/static/downloadApp/qrAppStore.svg'
import qrPlayStore from '../../../../assets/static/downloadApp/qrPlayStore.svg'
import appleIcon from '../../../../assets/static/downloadApp/appleIcon.svg'
import playStoreIcon from '../../../../assets/static/downloadApp/playStoreIcon.svg'
import { screenResolution } from '../../recoil/atoms/atoms'
import { appInsightsEventTrack } from '../../../pages/applicationInsights/ApplicationInsightDataMap'

import './DownloadApp.css'

const DownloadApp = () => {
  const screenResolutionDevices = useRecoilValue(screenResolution)
  const redirectToAppIfMobile = (appDownloadLink, buttonName) => {
    if (screenResolutionDevices?.screenResolution !== 'desktop') {
      if (buttonName === textJson?.downloadApp?.apple)
        appInsightsEventTrack('appStore')
      else if (buttonName === textJson?.downloadApp?.playStore)
        appInsightsEventTrack('playStore')

      window.open(appDownloadLink, '_blank')
    }
  }

  return (
    <div
      className="downloadApp"
      style={{
        // background: `url(${downloadAppBackground}) , rgb(from var(--primaryColor) r g b / 80%)`,
        background: `url(${downloadAppBackground}), var(--primaryColor)`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        // backgroundImage: `url(${downloadAppBackground})`,
      }}
    >
      {/* <div className="downloadAppBackgroundColor"></div> */}
      <div className="downloadAppDiv">
        <div className="titleDiv">
          <p>{textJson?.downloadApp?.title}</p>
        </div>
        <div className="contentDiv">
          <p>{textJson?.downloadApp?.downloadProcess}</p>
          <p>
            {screenResolutionDevices?.screenResolution !== 'desktop'
              ? textJson?.downloadApp?.howToDownloadThroughMobile
              : textJson?.downloadApp?.howToDownload}
          </p>
        </div>
        <div className="appPosterDiv">
          <div
            data-testid="appStore"
            className={`posters appStore ${
              screenResolutionDevices?.screenResolution !== 'desktop'
                ? 'simpleButton'
                : 'qrButton'
            }`}
            onClick={() =>
              redirectToAppIfMobile(
                'https://apps.apple.com/gb/app/apple-store/id1550489843',
                textJson?.downloadApp?.apple
              )
            }
          >
            <img className="qrCode" src={qrAppStore}></img>
            <div className="store">
              <img className="storeIcon" src={appleIcon} />
              <p className="storeName">{textJson?.downloadApp?.apple}</p>
            </div>
          </div>
          <div
            data-testid="playStore"
            className={`posters playStore ${
              screenResolutionDevices?.screenResolution !== 'desktop'
                ? 'simpleButton'
                : 'qrButton'
            }`}
            onClick={() =>
              redirectToAppIfMobile(
                'https://play.google.com/store/apps/details?id=com.logicvalley.selfservice',
                textJson?.downloadApp?.playStore
              )
            }
          >
            <img className="qrCode" src={qrPlayStore}></img>
            <div className="store">
              <img className="storeIcon" src={playStoreIcon} />
              <p className="storeName">{textJson?.downloadApp?.playStore}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DownloadApp
