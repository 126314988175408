/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
// System defined variables
import React from 'react'
import { Backdrop, CircularProgress } from '@mui/material'
import { usePromiseTracker } from 'react-promise-tracker'
// Custom defined variables
import './BackdropSpinner.css'

const BackdropSpinner = () => {
  const { promiseInProgress } = usePromiseTracker({ delay: 500 })
  return (
    <Backdrop className="backdropSpinner" open={promiseInProgress === true}>
      <div>
        <CircularProgress color={'primary'} />
      </div>
    </Backdrop>
  )
}
export default BackdropSpinner
