/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/* istanbul ignore file */
import { ApplicationInsights } from '@microsoft/applicationinsights-web'
import {
  ReactPlugin,
  withAITracking,
} from '@microsoft/applicationinsights-react-js'
import { globalHistory } from '@reach/router'
import utils from '../../../utils/utils'

const reactPlugin = new ReactPlugin()
let appInsights = null

export const initializeAppInsights = (instrumentationKey) => {
  if (!instrumentationKey) {
    return null
  }

  appInsights = new ApplicationInsights({
    config: {
      instrumentationKey,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: globalHistory },
      },
      disableFetchTracking: false, // Enable Fetch request tracking
    },
  })

  appInsights.loadAppInsights()
  appInsights.addTelemetryInitializer((envelope) => {
    const cookieData = utils?.getCookies()
    if (Object.keys(cookieData)?.length > 0 && cookieData?.accept === false) {
      if (
        [
          'RemoteDependencyData',
          'PageviewPerformanceData',
          'MessageData',
          'ExceptionData',
          'PageviewData',
        ].includes(envelope?.baseType)
      ) {
        return false
      }
    }
  })

  return appInsights
}

export const setAppInsightsKey = (key) => {
  initializeAppInsights(key)
}

export default (Component) => withAITracking(reactPlugin, Component)
export { appInsights }
