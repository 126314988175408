/*
 This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC),
 is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
 */

/*
  This component will display a banner on application indication cookie followed sight. By accept/reject the cookie the app-insight tracks will get update based on the setup
*/
// System defined variables
import React from 'react'
import { useNavigate } from 'react-router-dom'
// import CloseIcon from '@mui/icons-material/Close'

// Custom defined variables
import Button from '../../base/button/Button'
import utils from '../../../../utils/utils'
import textJson from '../../../../../src/json/Text.json'
import './CookieBanner.css'

const CookieBanner = ({ setShowCookieBanner, setCookieStorage }) => {
  const navigate = useNavigate()

  const reject = (buttonName) => {
    const cookieData = {
      accept: false,
    }
    utils?.setCookies({ ...cookieData })
    setCookieStorage({ ...cookieData })
    onCloseCookieBanner()
  }
  const accept = (buttonName) => {
    const cookieData = {
      accept: true,
    }
    utils?.setCookies({ ...cookieData })
    setCookieStorage({ ...cookieData })
    onCloseCookieBanner()
  }
  const onCloseCookieBanner = () => {
    setShowCookieBanner(false)
  }

  const navigateToPolicy = () => {
    navigate('/cookiepolicy')
  }

  return (
    <div className="cookieBanner">
      <div className="bannerDiv">
        <div className="contentDiv">
          <p className="bannerDescription">
            {textJson?.cookieBanner?.bannerDescription}
            <span
              data-testid="cookiePolicyLinkButton"
              className="hyperLink"
              onClick={navigateToPolicy}
            >
              {textJson?.cookieBanner?.bannerDescriptionMoreInfo}
            </span>
          </p>
          <div className="cookieButtons">
            <Button
              variant="contained"
              field={{
                value: textJson?.cookieBanner?.accept,
                label: textJson?.cookieBanner?.accept,

                type: 'primary',
                disabled: false,
                appInsightDataKey: 'acceptCookies',
              }}
              className="mobileResponsive"
              dataTestId="accept"
              onClickHandler={() => accept(textJson?.cookieBanner?.accept)}
            />
            <Button
              variant="contained"
              field={{
                value: textJson?.cookieBanner?.reject,
                label: textJson?.cookieBanner?.reject,
                type: 'secondary',
                disabled: false,
                appInsightDataKey: 'rejectCookies',
              }}
              className="mobileResponsive"
              dataTestId="reject"
              onClickHandler={() => reject(textJson?.cookieBanner?.reject)}
            />
          </div>
        </div>

        {/* <div
              className="closeButton"
              onClick={cookieCloseIconClick}
              data-testid="closeBanner"
              
            >
              <CloseIcon className="closeButtonIcon" />
            </div> */}
      </div>
    </div>
  )
}

export default CookieBanner
