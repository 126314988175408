/* istanbul ignore file */
const config = {}
export default config

function load() {
  return fetch('config.json')
    .then((result) => result.json())
    .then((configResponse) => {
      for (const property in config) {
        delete config[property]
      }
      for (const property in configResponse) {
        config[property] = configResponse[property]
      }
      return config
    })
}
export { load }
