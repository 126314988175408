/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/

/*
    #utils  : in this file, we used common functionalities like, formating, convertion, mapping, etc..
*/
import { createTheme } from '@mui/material'
import { appInsights } from '../views/pages/applicationInsights/ApplicationInsights'
// function to sort string and numeric values in array of objects based on a key -- start
function sortArrayOfObjects(arrayOfObjectsToSort, sortByKey) {
  let arrayOfObjects = arrayOfObjectsToSort
  // If Array has NONE property Shift before sort and unshift after sort
  let hasNoneProperty = false
  if (
    arrayOfObjects &&
    arrayOfObjects?.length > 0 &&
    (arrayOfObjects[0]?.Name === '-- None --' ||
      arrayOfObjects[0]?.id === '-- None --')
  ) {
    hasNoneProperty = true
    arrayOfObjects.shift()
  }
  const stringArr = [] // will have null, undefined and others
  const numericArr = [] //will have numeric data
  arrayOfObjects?.forEach((obj) => {
    //Separate arrayOfObjects data to numeric and string array
    if (
      obj[sortByKey] !== undefined &&
      obj[sortByKey] !== null &&
      isNaN(obj[sortByKey]?.toString().trim() ? obj[sortByKey] : '_') // since IsNaN('  ') returns  -> false, making it true with underscore
    )
      stringArr.push(obj)
    else numericArr.push(obj)
  })

  // string sort -> if same string -> small case will have higher priority
  stringArr.sort((a, b) => {
    const data =
      a[sortByKey] && a[sortByKey] !== null && a[sortByKey] !== undefined
        ? a[sortByKey]?.toString()
        : null
    const compareData =
      b[sortByKey] && b[sortByKey] !== null && b[sortByKey] !== undefined
        ? b[sortByKey]?.toString()
        : null

    if (data?.toLowerCase() === compareData?.toLowerCase()) {
      let returnData = 0
      for (
        let dataCharIndex = 0;
        dataCharIndex < data?.length;
        dataCharIndex++
      ) {
        for (
          let compareDataCharIndex = 0;
          compareDataCharIndex < compareData?.length;
          compareDataCharIndex++
        ) {
          if (
            data.charCodeAt(data[dataCharIndex]) <
            compareData.charCodeAt(compareData[compareDataCharIndex])
          ) {
            compareDataCharIndex = compareData?.length
            dataCharIndex = data?.length
            returnData = 1
          }
          if (
            data.charCodeAt(data[dataCharIndex]) >
            compareData.charCodeAt(compareData[compareDataCharIndex])
          ) {
            compareDataCharIndex = compareData?.length
            dataCharIndex = data?.length
            returnData = -1
          }
        }
      }
      return returnData
    }
    if (data?.toLowerCase() < compareData?.toLowerCase()) return -1
    if (data?.toLowerCase() > compareData?.toLowerCase()) return 1
  })

  // numeric sort
  numericArr.sort((a, b) => {
    return parseFloat(a[sortByKey]) - parseFloat(b[sortByKey])
  })
  Array.prototype.push.apply(numericArr, stringArr)
  arrayOfObjects = numericArr

  if (hasNoneProperty)
    arrayOfObjects.unshift({ Name: '-- None --', id: '-- None --' })
  return arrayOfObjects
}

const axiosConfiguration = {
  authorization: {
    apiKey: 'apiKey',
    userToken: 'userToken',
  },
  contentType: {
    urlEncoded: {
      key: 'urlEncoded',
      value: 'application/x-www-form-urlencoded',
    },
    applicationJson: {
      key: 'applicationJson',
      value: 'application/json',
    },
    applicationOctetStream: {
      key: 'applicationOctetStream',
      value: 'application/octet-stream',
    },
  },
}

const caseNumberFormat = {
  minLength: 7,
  regEx: /^[a-zA-Z0-9]*$/,
  errorMessage: {
    format: 'Must be alpha numeric of minimum 7 digits.',
    duplicate: 'Duplicate entry.',
    duplicateInGrid:
      'This enforcement agent reference already exists in the grid.',
    invalidCaseNumber: 'Invalid Case number',
    invalidQrCaseFormat:
      'Invalid enforcement agent reference number. Please input the reference manually.',
  },
}

//
const breadcrumbAndNavigation = {
  home: '/',
  quickPay: '/caselist',
}

const emptyFunction = () => {}

/** API Response code */
const apiResponseCode = {
  successCode: 200,
  partialContent: 206,
  internalServerError: 500,
  badRequest: 400,
  notFound: 404,
  noContent: 204,
}
const toastStatus = {
  error: 'error',
  success: 'success',
  warning: 'warning',
}

const apiSuccessResponseStatus = (status) => {
  return status?.toString()?.toLowerCase() === 'true'
}

const validateCaseNumberFormat = (caseNumber) => {
  if (
    caseNumber?.length < caseNumberFormat?.minLength ||
    !caseNumberFormat?.regEx?.test(caseNumber)
  )
    return false
  return true
}
const validateCaseNumberData = (caseNumberData) => {
  if (Array.isArray(caseNumberData)) {
    let resultArray = []
    let errorExists = false
    caseNumberData?.forEach((caseNumber, index) => {
      const validFormat = validateCaseNumberFormat(caseNumber)
      let duplicate = true
      let errorMessage = ''

      if (validFormat) {
        duplicate =
          caseNumberData?.indexOf(caseNumber) !==
          caseNumberData?.lastIndexOf(caseNumber)
      }
      errorMessage = validFormat
        ? duplicate
          ? caseNumberFormat?.errorMessage?.duplicate
          : ''
        : caseNumberFormat?.errorMessage?.format
      if (errorMessage) {
        errorExists = true
      }
      resultArray[index] = errorMessage
    })
    return { resultArray, errorExists }
  } else {
    return validateCaseNumberFormat(caseNumberData)
  }
}
/* istanbul ignore next */
const isAlphaNumeric1 = (value, alphaNumericMust = true) => {
  const alphaNumericRegex = /[a-zA-Z0-9]/g
  if (alphaNumericRegex.test(value)) {
    return true
  }
  return false
}
/* istanbul ignore next */
const isValidPhoneNumber = (value) => {
  const validPhoneNumberRegEx = /^[0-9\b]+$/
  if (validPhoneNumberRegEx.test(value)) {
    return true
  }
  return false
}
/* istanbul ignore next */
const isAlphaNumeric = (value, alphaNumericMust = true) => {
  if (!alphaNumericMust) {
    const alphaNumericWithSpace = /^[\w\-\s]+$/
    if (!value.match(alphaNumericWithSpace) || !isNaN(value)) {
      return false
    }
  } else {
    const alphaNumeric = /^(?=.*?\d)(?=.*?[a-zA-Z])[a-zA-Z\d]+$/
    if (!value.match(alphaNumeric)) {
      return false
    }
  }
  return true
}

const tooltipTheme = (customize) => {
  return createTheme({
    components: {
      MuiTooltip: {
        styleOverrides: {
          arrow: () => ({
            color: 'var(--tooltipBackgroundColor) !important',
            ...customize?.arrow,
          }),
          tooltip: {
            fontFamily: 'var(--defaultFontName) !important',
            fontSize: 'var(--fontSize14) !important',
            fontWeight: 'var(--fontWeightNormal) !important',
            color: 'var(--whiteColor) !important',
            backgroundColor: 'var(--tooltipBackgroundColor) !important',
            margin: '0px !important',
            marginBottom: '10px !important',
            borderRadius: 'var(--defaultBorderRadius) !important',
            padding: '1rem',
            ...customize?.tooltip,
          },
        },
      },
    },
  })
}

const convertToCurrency = (amount) => {
  let pounds = Intl.NumberFormat('en-GB', {
    style: 'currency',
    currency: 'GBP',
  })
  return pounds?.format(amount)
}

const setCookies = (cookieObject) => {
  for (const [key, value] of Object.entries(cookieObject)) {
    document.cookie = `${key}=${encodeURIComponent(value)};samesite=Lax`
  }
}
const getCookies = () => {
  var regexPatternTrue = new RegExp('true')
  const cookieString = decodeURIComponent(document?.cookie)
  const cookieArray = cookieString?.split(';')
  const cookieStorage = {}
  cookieArray?.forEach((data) => {
    if (data?.indexOf('accept=') >= 0) {
      cookieStorage.accept = regexPatternTrue?.test(
        data?.replace(/[ ]/g, '')?.substring('accept='?.length, data?.length)
      )
    }
  })
  return cookieStorage
}
const verificationFlow = {
  register: 'register',
  login: 'login',
}

const decimalValueFormate = (inputValue) => {
  const regex = /^\d*\.?\d{0,2}$/
  if (regex.test(inputValue)) {
    return true
  }
  return false
}

const frequencySet = [
  {
    name: 'Weekly',
    value: 'Weekly',
  },
  {
    name: 'Fortnightly',
    value: 'Fortnightly',
  },
  {
    name: 'Monthly',
    value: 'Monthly',
  },
]
const weekDaysDataSource = [
  {
    id: 1,
    name: 'Monday',
  },
  {
    id: 2,
    name: 'Tuesday',
  },
  {
    id: 3,
    name: 'Wednesday',
  },
  {
    id: 4,
    name: 'Thursday',
  },
  {
    id: 5,
    name: 'Friday',
  },
]
const deviceType = {
  web: '3',
}
const getCurrentDate = () => {
  const date = new Date()
  const day = date
    .getUTCDate()
    ?.toLocaleString('en-US', { minimumIntegerDigits: 2, useGrouping: false })
  const month = (date.getUTCMonth() + 1)?.toLocaleString('en-US', {
    minimumIntegerDigits: 2,
    useGrouping: false,
  }) // Months are zero-based
  const year = date.getUTCFullYear()
  const formattedDate = `${day}/${month}/${year}`
  return formattedDate
}
const employeeWorkingStatus = [
  {
    id: 1,
    name: 'Full Time',
  },
  {
    id: 2,
    name: 'Part Time',
  },
  {
    id: 3,
    name: 'Self-Employed',
  },
  {
    id: 4,
    name: 'Un-Employed',
  },
  {
    id: 5,
    name: 'Retired',
  },
]
const paidForDataSource = [
  {
    name: 'Weekly',
    value: 2,
  },
  {
    name: 'Monthly',
    value: 1,
  },
]
// Years Array
const yearsDataSource = Array.from({ length: 90 }, (v, k) => ({
  id: k + 1,
  name: k + 1,
}))

const commonDateTimeFormat = {
  date: 'DD/MM/YYYY',
}

// Months Array
const monthsDataSource = Array.from({ length: 11 }, (v, k) => ({
  id: k + 1,
  name: k + 1,
}))

const appInsightsEventsTrack = (params) => {
  appInsights.trackEvent(params)
}

export default {
  sortArrayOfObjects,
  axiosConfiguration,
  emptyFunction,
  caseNumberFormat,
  apiResponseCode,
  toastStatus,
  apiSuccessResponseStatus,
  validateCaseNumberFormat,
  validateCaseNumberData,
  breadcrumbAndNavigation,
  isAlphaNumeric,
  isValidPhoneNumber,
  tooltipTheme,
  convertToCurrency,
  setCookies,
  getCookies,
  verificationFlow,
  decimalValueFormate,
  frequencySet,
  weekDaysDataSource,
  deviceType,
  getCurrentDate,
  commonDateTimeFormat,
  employeeWorkingStatus,
  paidForDataSource,
  yearsDataSource,
  monthsDataSource,
  appInsightsEventsTrack,
}
