/*
This computer program, as defined in the Copyright, Designs and Patents Act 1998 and the Software Directive (2009/24/EC), 
is the copyright of Logic Valley Ltd, a wholly owned subsidiary of Marston (Holdings) Ltd. All rights are reserved.
*/
/*
  This Dropdown component used to select the option value from the list of records / data
  onChangeHandler : onChangeHandler is callback function recieved from parent component for send the user selected value to parent component
  Field           : Field props recieved from parent component with datasource
  className       : className props recieved from parent component for display and modify the style based on parent component
*/

import React, { useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { FormControl, Select, MenuItem, InputLabel } from '@material-ui/core'
import utils from '../../../../utils/utils'
import './Dropdown.css'
//className, fieldErrorMessage
const Dropdown = ({
  Field,
  value,
  onChangeHandler,
  variant,
  onBlur,
  outlineError,
  required,
  tabIndex,
  restrictSorting = false,
  ...rest
}) => {
  let dataSource = Field?.datasource
  const inputElementDiv = useRef() // The whole div ref of input - used for popperReference
  const [dropdownWidth, setDropdownWidth] = useState(0)
  if (!restrictSorting)
    dataSource = utils?.sortArrayOfObjects(Field?.datasource, Field?.textField)
  outlineError?.splice(1)
  /*
    This is used to generate templete to display the control's lable, requried fiels symbol, field error message and help text
  */
  const controlLabel = Field?.fieldLabel ? (
    <div className="controlLabelDiv">
      <span className="controlLabel">{Field && Field?.fieldLabel}</span>
      <span>{outlineError && outlineError}</span>
      {required || Field?.validation?.isRequired?.toLowerCase() === 'true' ? (
        <span className="Required-color"> *</span>
      ) : (
        ''
      )}
    </div>
  ) : null

  /*
   This onChange function used to pass the selected value to parent component via callback function (onChangeHandler)
  */
  function onChange(event) {
    if (onChangeHandler)
      onChangeHandler(event, {
        id: Field?.fieldValue,
        value: event?.target?.value,
        type: 'select',
        selectedLabel: event?.target?.value,
      })
  }
  const updateDropdownWidth = () => {
    setDropdownWidth(inputElementDiv?.current?.offsetWidth)
  }
  return (
    <div className="dropdownctrl">
      <div
        className={`Text-field-container ${Field.className}`}
        style={{ height: '100%' }}
      >
        <FormControl
          size="small"
          variant={variant || 'filled'}
          className={`${Field.className} ${'dropdown'}`}
          style={{ height: 'inherit' }}
          ref={inputElementDiv}
          // required={required}
        >
          {Field?.fieldValue && (
            <InputLabel
              htmlFor={Field?.fieldValue}
              id="demo-simple-select-outlined-label"
            >
              {controlLabel}
            </InputLabel>
          )}
          <Select
            id={Field?.fieldValue}
            className={Field.className}
            variant={'filled'}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left',
              },
              getContentAnchorEl: null,
              PaperProps: {
                style: {
                  width: dropdownWidth ? `${dropdownWidth}px` : 'auto',
                },
              },
            }}
            onChange={(e) => onChange(e)}
            label={controlLabel}
            disabled={Field?.disabled}
            displayEmpty={Field?.placeholder?.length > 0 ? true : false}
            defaultValue={Field?.defaultValue}
            value={value || ''}
            disableUnderline={true}
            onBlur={onBlur}
            inputProps={{
              id: Field?.fieldValue,
              'data-testid': rest?.dataTestId
                ? rest?.dataTestId
                : 'SelectElement',
              tabIndex: tabIndex,
            }}
            onMouseDown={() => {
              updateDropdownWidth()
            }}
            renderValue={(value) => {
              if (value === '' && Field?.placeholder?.length > 0) {
                return (
                  <em>
                    <p>{Field?.placeholder}</p>
                  </em>
                )
              }
              return (
                <p>
                  {dataSource.find((item) => item.id === value)?.name || ''}
                </p>
              )
            }}
          >
            {dataSource &&
              dataSource.map((option, idx) => {
                return (
                  <MenuItem
                    key={`${option[Field.valueField]}_${idx + 1}`}
                    value={option[Field.valueField]}
                    className="dropdownOptionMenuItem"
                  >
                    <span
                      className={`dropdownOptionText ${
                        Field?.translate === true ? 'notranslate' : ''
                      }`}
                    >
                      {option[Field.textField]}
                    </span>
                  </MenuItem>
                )
              })}
          </Select>
        </FormControl>
      </div>
    </div>
  )
}

Dropdown.defaultProps = {
  className: '',
  value: '',
  onChangeHandler: () => {},
}

export default Dropdown
